<template>
  <div class="Signature">
    <div class="signature-view">{{agree.signatureName}} <span class="signature-view__date">{{agree.dateFormatted}}</span></div>
    <v-text-field
        type="text"
        label="Type name here and check below to sign:"
        outlined
        :error="error"
        v-model="agree.signatureName"
        hide-details
    />
    <v-checkbox
        class="agreementCheckbox"
        v-model="agree.signatureCheckbox"
        :error="error"
        :label="agreement"
    />
  </div>
</template>

<script>
import { format } from 'date-fns';
export default {
  name: 'Signature',
  props: {
    value: {
      type: Object,
    },
    error: {
      type: Boolean,
      default: false,
    },
    agreement: {
      type: String,
      // default: 'You represent and warrant that the individual electronically agreeing to the terms of this Agreement is authorized and empowered to agree to this Agreement on your behalf. You further agree that checking a box to acknowledge your assent to this Agreement and/or clicking the "AGREE" button and/or performing any other similar electronic affirmation constitutes an electronic signature as defined by the Electronic Signatures in Global and National Commerce Act and that this Agreement is completely valid, has legal effect, is enforceable, and is binding on and non-refutable by you.',
      default: 'You represent and warrant that the individual electronically signing this new clinic registration form is authorized and empowered to sign on behalf of the clinic. You further agree that checking a box to acknowledge that this is an electronic signature as defined by the Electronic Signatures in Global and National Commerce Act and that this signature is valid.',
      description: 'Agreement they are agreeing to'
    }
  },
  data() {
    return {
      justEmitted: false,
      agree: {
        signatureName: '',
        signatureCheckbox: false,
        date: '',
      },
    };
  },
  computed: {
    isComplete() {
      return this.agree.signatureName && this.agree.signatureCheckbox;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
          this.agree.signatureName = val.signatureName || '';
          this.agree.signatureCheckbox = val.signatureCheckbox || false;
          this.agree.date = val.date || null;
      }
    },
    isComplete: {
      deep: true,
      handler(val) {
          if (val) {
            const d = new Date()
            this.agree.dateFormatted = format(d, 'yyyy-MM-dd hh:mm a');
            this.agree.date = d.toISOString();
          } else {
            this.agree.dateFormatted = '';
            this.agree.date = '';
          }
          this.$emit('input', this.agree);
      },
    }
  }
};
</script>

<style lang="scss">
.agreementCheckbox {
  label.v-label {
    font-size: 13px!important;
    line-height: 1;
  }
}
</style>

<style lang="scss" scoped>
  .signature-view {
    font-family: cursive;
    font-size: 40px;
    margin-bottom: .5rem;
    &__date {
      font-size: 16px;
    }
  }
  .agreementCheckbox {
    margin-top: 0;
  }
</style>
