<template>
  <div class="lead">
    <div class="lead__container">
      <div class="image">
        <v-img class="fluid-flow-image" src="@/assets/EMS-Bio_LogoFINAL_color_horiz_web.png" alt="Emsbio Logo"/>
      </div>
      <div
          class="confirmation"
          v-if="showConfirmation"
      >
        <h1>Thank you!</h1>
        <p class="sub-text">We will be contacting you within 1 business day. We look forward to talking to you!</p>
      </div>
      <v-card
          v-else
          class="col-12 step-card show-scrollbar"
      >
        <p>Thank you for your interest in partnering with us! Please fill out the information below to provide us with
          the necessary information about your clinic and your providers so that we can effectively work with you in
          integrating Fluid GF™ into your practice.</p>
        <div class="scrolling">


          <div class="section-header">Physician/Practice Information</div>
          <!-- practice name -->
          <v-text-field
              label="Practice Name"
              v-model="formData.practiceName"
              :error="isError.practiceName"
              @input="isError.practiceName = false"
              @blur="saveFormInfo"
              class="col-12"
              outlined
              dense
          />
          <!-- practice name -->
          <v-text-field
              label="Physician Name"
              v-model="formData.physicianName"
              :error="isError.physicianName"
              @input="isError.physicianName = false"
              @blur="saveFormInfo"
              class="col-12"
              outlined
              dense
          />
          <div class="horizontal">
            <!-- practice name -->
            <v-text-field
                label="Contact Name"
                v-model="formData.practiceContactName"
                :error="isError.practiceContactName"
                @input="isError.practiceContactName = false"
                @blur="saveFormInfo"
                class="col-6"
                outlined
                dense
            />
            <v-text-field
                label="Contact Email"
                v-model="formData.practiceContactEmail"
                :error="isError.practiceContactEmail"
                @input="isError.practiceContactEmail = false"
                @blur="saveFormInfo"
                class="col-6"
                outlined
                dense
            />
          </div>
          <div class="horizontal">
            <v-text-field
                type="number"
                v-model="formData.practicePhone"
                :error="isError.practicePhone"
                @input="isError.practicePhone = false"
                @blur="saveFormInfo"
                label="Office Phone"
                class="col-6"
                outlined
                dense
            />
            <v-text-field
                type="number"
                label="Office Mobile Phone"
                v-model="formData.practiceMobilePhone"
                :error="isError.practiceMobilePhone"
                @input="isError.practiceMobilePhone = false"
                @blur="saveFormInfo"
                class="col-6"
                outlined
                dense
            />
            <v-text-field
                label="Office Email"
                v-model="formData.practiceEmail"
                :error="isError.practiceEmail"
                @input="isError.practiceEmail = false"
                @blur="saveFormInfo"
                class="col-6"
                outlined
                dense
            />
          </div>
          <!-- practice street -->
          <v-text-field
              v-model="formData.practiceStreet"
              :error="isError.practiceStreet"
              @input="isError.practiceStreet = false"
              @blur="saveFormInfo"
              label="Street Address"
              class="col-12"
              outlined
              dense
          />
          <div class="horizontal">
            <v-text-field
                class="col-4"
                label="City"
                v-model="formData.practiceCity"
                @input="isError.practiceCity = false"
                @blur="saveFormInfo"
                :error="isError.practiceCity"
                outlined
                dense
            />
            <v-autocomplete
                class="col-4"
                v-model="formData.practiceState"
                :error="isError.practiceState"
                @input="isError.practiceState = false"
                @blur="saveFormInfo"
                :items="states"
                label="State"
                persistent-hint
                outlined
                dense
            />
            <v-text-field
                class="col-4"
                label="Zip"
                v-model="formData.practiceZip"
                :error="isError.practiceZip"
                @input="isError.practiceZip = false"
                @blur="saveFormInfo"
                outlined
                dense
            />

          </div>
          Office Hours:
          <div
              v-for="day in days"
              :key="day.value"
              class="horizontal horizontal__checkbox"
          >
                  <span class="mr-2">{{ day.text }}:
                    <span class="hours"
                        v-if="formData.businessHours[day.value].open"
                    >
                      {{ formData.businessHours[day.value].start }} - {{ formData.businessHours[day.value].end }}
                    </span>
                    <span class="hours" v-else>
                      closed
                    </span>
                  </span>
            <div class="horizontal mr-2" v-if="checks[day.value]">
              <v-text-field
                  type="number"
                  label="Start"
                  class="col-6"
                  v-model="formData.businessHours[day.value].start"
                  @blur="saveFormInfo"
                  outlined
                  dense
                  hide-details
              />
              <v-text-field
                  type="number"
                  label="End"
                  class="col-6"
                  v-model="formData.businessHours[day.value].end"
                  @blur="saveFormInfo"
                  outlined
                  dense
                  hide-details
              />
              <v-checkbox
                  label="open?"
                  v-model="formData.businessHours[day.value].open"
              />
            </div>
            <v-icon @click="checks[day.value] = !checks[day.value]">
              {{ checks[day.value] ? 'fal fa-check' : 'fal fa-edit' }}
            </v-icon>
          </div>
          <div class="section-header">Practice Providers and/or Facility Information</div>
          <div class="horizontal" :class="{
                  'mt-2': i !== 0,
                }" v-for="(provider, i) in formData.providers" :key="i">
            <v-text-field
                label="Provider Name, Credentials"
                v-model="provider.name"
                :error="isError.providers"
                @input="isError.providers = false"
                @blur="saveFormInfo"
                class="col-4"
                outlined
                dense
                hide-details
            />
            <v-text-field
                label="NPI #"
                v-model="provider.npi"
                :error="isError.providers"
                @input="isError.providers = false"
                @blur="saveFormInfo"
                class="col-4"
                outlined
                dense
                hide-details
            />
            <v-text-field
                label="License #"
                v-model="provider.license"
                :error="isError.providers"
                @input="isError.providers = false"
                @blur="saveFormInfo"
                class="col-4"
                outlined
                dense
                hide-details
            />
            <v-icon
                @click="removeProvider(i, $event)"
                class="ml-2"
                v-if="i !== 0"
            >fal fa-times
            </v-icon>
          </div>
          <v-btn
              outlined
              icon
              @click="addProvider"
              class="add-more-btn"
          >
            <v-icon>fal fa-plus</v-icon>
          </v-btn>
          <div class="section-header">Insurance Information</div>
          <v-switch
              v-model="formData.medicare"
              label="Do you accept Medicare?"
          ></v-switch>
          Top 3 Procedures
          <div class="horizontal">
            <v-text-field
                type="text"
                label="Procedure"
                class="col-4"
                v-model="formData.procedure1"
                :error="isError.procedure1"
                @input="isError.procedure1 = false"
                @blur="saveFormInfo"
                outlined
                dense
                hide-details
            />
            <v-text-field
                type="text"
                label="Procedure"
                class="col-4"
                v-model="formData.procedure2"
                :error="isError.procedure2"
                @input="isError.procedure2 = false"
                @blur="saveFormInfo"
                outlined
                dense
                hide-details
            />
            <v-text-field
                type="text"
                label="Procedure"
                class="col-4"
                v-model="formData.procedure3"
                :error="isError.procedure3"
                @input="isError.procedure3 = false"
                @blur="saveFormInfo"
                outlined
                dense
                hide-details
            />
          </div>
          <div class="mt-3">Signature</div>
          <div class="info-signature">
            <Signature
                v-model="formData.infoSignature"
                :error="isError.infoSignature"
                @input="onSignatureInput"
            />
          </div>


        </div>
        <v-card-actions class="pt-4 pb-0">
          <div class="submit-btn flex flex-column">
            <div class="missing-data" v-if="stepIsInvalid">Looks like your missing some info above</div>
            <v-btn
                :disabled="stepIsInvalid"
                color="success"
                @click="submitForm()"
            >
              Submit
            </v-btn>
          </div>

        </v-card-actions>

      </v-card>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Signature from "@/components/Signature";
import { uuid } from "@/helpers/uuid";
import { store } from "@/helpers/store"
import { db, analytics } from "@/helpers/firebase";
import format from 'date-fns/format';

const dbCollectionName = 'leads';

export default {
  name: 'NextSteps',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Next Steps',
    meta: [
      {
        name: 'description',
        content: 'You\'ve decided to try Biolab fluid GF Q4206, now what?'
      },
    ],
    // all titles will be injected into this template
    titleTemplate: '%s | Biolab fluid GF Q4206'
  },
  components: {
    Signature,
  },
  data() {
    return {
      showConfirmation: false, // should we show the confirmation page?
      paymentForms: {
        card: 'https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/Credit%20Card%20Authorization%20Form.pdf?alt=media&token=b1dd2391-0430-4c9c-8c21-3e65adac7abf',
        ach: 'https://firebasestorage.googleapis.com/v0/b/emsbio-19cd7.appspot.com/o/ACH%20Authorization%20Form.pdf?alt=media&token=149e639c-427e-462e-9482-2df20b2393ba',
        financing: 'https://nam10.safelinks.protection.outlook.com/?url=https%3A%2F%2Fapp.bluevine.com%2Fsignup2%3Fpid_login%3D0003259&data=04%7C01%7CRAgar%40prioritycapital.com%7Ca669cebd4c4446c47ab008d881154507%7Cb062e0c145e04b1bb0b6deaeb2041e41%7C0%7C1%7C637401275649201230%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0&sdata=sTOrEJMBQNJFcenFmzZK9aQ4jy3%2BmAzTP3J68MgXHCE%3D&reserved=0',
      },
      debugging: window.location.href.includes('debugForm=true'),
      // debugging: false,
      purchaseType: '20',
      costOfFluid: 1200,
      step: 1,
      formData: {
        leadID: uuid(),
        paymentType: '',
        medicare: false,
        purchaseCount: 20,
        practiceName: '',
        physicianName: '',
        practiceContactName: '',
        practiceStreet: '',
        practiceCity: '',
        practiceState: '',
        practiceZip: '',
        practicePhone: '',
        practiceMobilePhone: '',
        practiceEmail: '',
        practiceContactEmail: '',
        callMeName: '',
        callMePhone: '',
        callMeBestTime: '',
        businessHours: {
          mon: {
            start: '8',
            end: '5',
            open: true,
          },
          tue: {
            start: '8',
            end: '5',
            open: true,
          },
          wed: {
            start: '8',
            end: '5',
            open: true,
          },
          thu: {
            start: '8',
            end: '5',
            open: true,
          },
          fri: {
            start: '8',
            end: '5',
            open: true,
          },
          sat: {
            start: '8',
            end: '5',
            open: false,
          },
          sun: {
            start: '8',
            end: '5',
            open: false,
          },
        },
        infoSignature: {},
        eSignName: '',
        eSign: {},
        providers: [], // see addProvider
        procedure1: '',
        procedure2: '',
        procedure3: '',
        leadStarted: new Date().toISOString(),
        leadStartedLocalDate: format(new Date(), 'yyyy-MM-dd'),
        leadStartedLocalTime: format(new Date(), 'HH:mm'),
      },
      checks: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,

      },
      isError: {}, // setup on create to watch all formData properties
      stepIsInvalid: false,
      lastSavedFormData: '', // to verify if we have saved data already or not.
    }
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        if (this.stepIsInvalid) {
          this.stepIsInvalid = !this.validateStep();
        }
      }
    },
    purchaseType(type) {
      if (type !== 'custom') {
        this.formData.purchaseCount = parseInt(type);
      }
    },
  },
  created() {
    this.setupErrorWatchers()
    this.addProvider();

    // ensure there is a cache of the form data
    const cashedStore = this.getStoreInfo();
    if (cashedStore) {
      this.$set(this, 'formData', cashedStore);
    } else {
      this.saveStoreInfo(this.formData);
    }
  },
  methods: {
    onSignatureInput() {
      this.isError.infoSignature = false;
      this.saveFormInfo();
    },
    validateStep() {
      let itPasses = true;

      const rules = this.validationRules[this.step];
      Object.keys(rules).forEach(dataName => {
        let toLoop;

        // if it's an object find out if we should validate or not
        if (!Array.isArray(rules[dataName])) {
          /**
           * shouldCheck {Function}
           * rules: {Array}
           */
          if (!rules[dataName].shouldCheck()) {
            return;
          }
          toLoop = rules[dataName].rules;
        } else {
          toLoop = rules[dataName];
        }

        const passes = toLoop.every(fnc => fnc(this.formData[dataName]));
        if (!passes) itPasses = false;

        // change true to false because it's an "IS error"
        this.isError[dataName] = !passes
      })

      return itPasses;
    },
    goToNextStep() {

      if (this.debugging) {
        this.step++
        return;
      }

      const passes = this.validateStep()
      if (passes) {
        const toCheck = JSON.stringify(this.formData);

        // check if we already saved this data
        if (this.lastSavedFormData !== toCheck) {

          this.saveFormInfo()
              .then(() => {
                this.lastSavedFormData = toCheck;
                analytics.logEvent(`lead_step_${this.step}_finished`);
                this.step++;
              })

        } else {
          this.step++;
        }

      } else {
        this.stepIsInvalid = true;
      }

    },
    saveFormInfo() {
      return db.collection(dbCollectionName)
          .doc(this.formData.leadID)
          .set(this.formData, { merge: true });
    },
    goToPrevStep() {
      this.stepisInvalid = false;
      this.step--;
    },
    submitForm() {
      if (this.debugging) {
        this.showConfirmation = true;
        return;
      }

      const passes = this.validateStep()
      if (!passes) {
        this.stepIsInvalid = true;
        // TODO err here
        return;
      }

      const date = new Date();
      this.formData.leadFinished = date.toISOString();
      this.formData.leadFinishedLocalDate = format(date, 'yyyy-MM-dd');
      this.formData.leadFinishedLocalTime = format(date, 'HH:mm');

      this.saveFormInfo()
          .then(() => {
            this.showConfirmation = true;
            analytics.logEvent('lead_received');
          })

    },
    setupErrorWatchers() {
      Object.keys(this.formData).forEach(key => {
        this.$set(this.isError, key, false);
      });
    },
    saveStoreInfo(info) {
      store.set('formData', info);
    },
    getStoreInfo() {
      return store.get('formData');
    },
    removeProvider(i) {
      this.formData.providers.splice(i, 1);
    },
    addProvider() {
      this.formData.providers.push({
        name: '',
        npi: '',
        license: '',
      })
    },
    formatMoney(count) {
      return Number((count || 1) * this.costOfFluid).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  },
  computed: {
    // stepIsInvalid() {
    //   debugger;
    //   if (!this.isError[this.step]) return false;
    //   return Object.keys(this.isError[this.step]).every(key => {
    //     return !this.isError[key];
    //   })
    // },
    validationRules() {
      // helper functions
      function hasLength(val) {
        return val.length > 0;
      }

      function isSigned(val) {
        return val.date && val.date.length > 0;
      }

      // const that = this;
      // rules
      return {
        1: {
          practiceName: [
            hasLength,
          ],
          physicianName: [
            hasLength,
          ],
          practicePhone: [
            hasLength,
          ],
          practiceEmail: [
            hasLength,
          ],
          practiceState: [
            hasLength,
          ],
          practiceCity: [
            hasLength,
          ],
          practiceZip: [
            hasLength,
          ],
          practiceMobilePhone: [
            hasLength,
          ],
          infoSignature: [
            isSigned,
          ],
          practiceContactEmail: [
            hasLength,
          ],
          practiceContactName: [
            hasLength,
          ],
        },
      }
    },
    buyResults() {
      return this.formatMoney(this.formData.purchaseCount);
    },
    states() {
      return [
        'Alabama', 'Alaska', 'American Samoa', 'Arizona',
        'Arkansas', 'California', 'Colorado', 'Connecticut',
        'Delaware', 'District of Columbia', 'Federated States of Micronesia',
        'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
        'Louisiana', 'Maine', 'Marshall Islands', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
        'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
        'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico',
        'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
        'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
      ]
    },
    days() {
      return [
        {
          text: 'Monday',
          value: 'mon',
        },
        {
          text: 'Tuesday',
          value: 'tue',
        },
        {
          text: 'Wednesday',
          value: 'wed',
        },
        {
          text: 'Thursday',
          value: 'thu',
        },
        {
          text: 'Friday',
          value: 'fri',
        },
        {
          text: 'Saturday',
          value: 'sat',
        },
        {
          text: 'Sunday',
          value: 'sun',
        },
      ];
    },
  },
}
</script>

<style lang="scss" scoped>
.missing-data {
  color: red;
}

.lead {
  &__container {
    .image {
      margin-top: 1rem;
    }
  }

  display: flex;
  justify-content: center;
  height: 100%;

  .scrolling {
    //overflow: auto;
    //max-height: calc(100vh - 345px);
    padding: 1rem;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  .confirmation {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      //color: #fb7c34;
      color: #00aeef;
    }

    .sub-text {
      max-width: 500px;
    }

    .paying-options {
      .box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          margin-bottom: 1rem;
        }

        .details {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .authorized-text {
    position: absolute;
    right: 1rem;
    bottom: 1.5rem;
  }

  @media only screen and (max-width: 600px) {
    .authorized-text {
      font-size: 10px;
    }
  }

  .orange-highlight {
    //color: #fb7c34;
    color: #00aeef;
  }

  .radio-group {
    margin-top: 0;
  }

  .cost-header {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .side-by-side-radio {
    display: flex;
    flex-direction: column;

    .label-wrapper {
      height: 100%;
    }
  }

  .payment-call-data {
    justify-content: space-around;
  }

  .stepper {
    flex: 1;
    margin-bottom: 1rem;
  }

  .add-more-btn {
    margin-top: .5rem;
  }

  .digital-form {
    border: 1px solid lightgray;
    padding: 0 1rem;
    line-height: 1.3;
    height: 230px;
    overflow: scroll;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }

  &__container {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stepper {
    min-width: 100%;
  }

  .v-stepper__step {
    padding: 24px 0;

    .v-stepper__label {
      width: 100%;
      align-items: center;
      text-align: center !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .v-stepper__step {
      padding: 12px 0 0 0;
    }
  }
}

@media only screen and (min-width: 601px) {
  .fluid-flow-image {
    max-width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .fluid-flow-image {
    max-height: 60px;
    width: 100vw;
  }
}

.count-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  &__single {
    min-width: 215px;
  }

  &__spacer {
    margin-bottom: 1rem;
  }

  &__field, &__results {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.amount {
  .v-radio {
    width: 30%;
    display: flex;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 600px) {
    .v-radio {
      width: 100%;
    }
  }

  .results__amount {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.horizontal {
  display: flex;
  align-items: center;

  .v-input:not(:first-child) {
    margin-left: 1rem;
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: .5rem 0;

    .hours {
      font-weight: bold;
    }
  }
}

.step-card {
  margin-bottom: 1rem;
  overflow: auto;
  padding: 1rem;

  .section-header {
    font-size: 20px;
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}

.show-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
</style>
